<template>
    <modal ref="modalCreateCategory" titulo="Crear categoría" no-aceptar adicional="Crear" @adicional="editarGuardar">
            <div class="row mx-0 my-3 j-center">
                <div class="col-8">
                    <p class="f-12 pl-3">Nombre de la categoría principal</p>
                    <el-input v-model="categoryName" placeholder="Nombre" class="w-100" size="small" />
                </div>
                <div class="col-8 mt-4">
                    <p class="f-12 text-center">Imagen</p>
                    <div class="position-relative d-middle-center">
                        <slim-cropper 
                        ref="logoCategoria" 
                        :options="slimOptions" 
                        class="border cr-pointer" 
                        style="height:237px;width:237px;background:#F8F9FF;border-radius:4px;z-index:4;" 
                        />
                    </div>
                </div>
            </div>
        </modal>
</template>
<script>
import Categorias from '~/services/tiendas'
export default {
    data(){
        return {
            categoryName: '',
            slimOptions: {
                ratio: "1:1",
                label: "Subir imagen",
                buttonCancelLabel: "Cancelar",
                buttonConfirmLabel: "ok",
                buttonUploadLabel: "Ok",
            },
            editarCategoria: false,
            idCategoria: null,
        }
    },
    methods: {
        toggle(tipo,idCategoria){
            this.editarCategoria = false;
            this.idCategoria = null;
            this.categoryName = '';
            if (tipo === 'editar') {
                this.editarCategoria = true;
                this.idCategoria = idCategoria;
                this.detalleCategoria(idCategoria);
            }else{
                this.$refs.logoCategoria.remove();
            }     
            this.$refs.modalCreateCategory.toggle();
        },
        editarGuardar(){
            if (this.editarCategoria) {
                this.editar();
            }else{
                this.create();
            }
        },
        async create(){
            try {
                if (this.categoryName === '') {
                    this.notificacion('No es posible', 'El nombre de la categoria es obligatorio', 'warning');
                    return;
                }

                const payload = {
                    nombre: this.categoryName,
                    imagen: this.$refs.logoCategoria.get_image()
                };
                
                const { data } = await Categorias.crearCategoria(payload)                
                if (data.success) {
                    this.notificacion('',data.mensaje,'success')
                    this.$emit('actualizar')
                }
                
                this.$refs.modalCreateCategory.toggle();
            } catch (error) {
                this.error_catch(error)
			}
        },
        async detalleCategoria(idCategoria){
            try {
                this.$refs.logoCategoria.remove();
                const { data } = await Categorias.detalleCategoria({idCategoria: idCategoria})                
                this.categoryName = data.data.nombre;
                this.$refs.logoCategoria.set_image(`${data.data.imagen}`)                
            } catch (error) {
                this.error_catch(error)
			}
        },
        async editar(){
            try {
                if (this.categoryName === '') {
                    this.notificacion('No es posible', 'El nombre de la categoria es obligatorio', 'warning');
                    return;
                }

                const payload = {
                    idCategoria: this.idCategoria,
                    nombre: this.categoryName,
                    imagen: this.$refs.logoCategoria.get_image()
                };
                
                const { data } = await Categorias.editarCategoria(payload)                
                if (data.success) {
                    this.notificacion('',data.mensaje,'success')
                }
                
                this.$refs.modalCreateCategory.toggle();
            } catch (error) {
                this.error_catch(error)
			}
        },
    }
}
</script>